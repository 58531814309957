import React, { useState } from 'react';
import { Liste } from '@jvs-group/jvs-mairistem-liste';
import {
  Form, Header, Icon, Popup,
} from '@jvs-group/jvs-mairistem-composants';
import { NavLink, useHistory } from 'react-router-dom';
import { FilterScope } from '@jvs-group/jvs-mairistem-finances-utils';
import ProjetListeTable from '@/src/components/ProjetListe/ProjetListeTable';
import { Projet } from '@/src/interfaces/projet';
import EtiquettesInput from '@/src/components/Common/EtiquettesInput';

interface ProjetListeProps {
  projets: Array<Projet>,
  count: number,
  perPage: number,
  loading: boolean,
  onLoad: any,
  onSearch: any,
  onSort: any,
  onSelected: any,
  onExplored: any,
  onExpanded: any,
  state: any,
  mode: any,
  query? : any
}

const ProjetListe = (props : ProjetListeProps) => {
  const {
    projets,
    count,
    perPage,
    loading,
    onLoad,
    onSort,
    onSelected,
    mode,
    query,
    ...handlers
  } = props;

  const [selectedEtiquettes, setSelectedEtiquettes] = useState([]);
  const [selectedStades, setSelectedStades] = useState<number[]>([1, 2, 3]);

  const handleEtiquetteChange = ({ etiquettes }) => {
    setSelectedEtiquettes(etiquettes);
  };

  const handleStadeChange = (e, { value }) => {
    if (selectedStades.includes(value)) {
      setSelectedStades(selectedStades.filter((selectedStade) => selectedStade !== value));
    } else {
      setSelectedStades([...selectedStades, value]);
    }
  };

  React.useEffect(() => {
    if (query) {
      query?.filter(() => ({
        ...(selectedEtiquettes.length > 0)
          && { 'etiquettesId[]': selectedEtiquettes.map((etiquette) => etiquette.identifiant) },
        ...(selectedStades.length > 0)
          && { status: FilterScope.filterIn(selectedStades) },
      }));
    }
  }, [selectedEtiquettes, query, selectedStades]);

  const history = useHistory();
  const handleClick = () => {
    history.push('/projets');
  };

  return (
    <>
      <Liste
        title="Projets"
        mode={mode}
        searchable
        // @ts-expect-error
        persistState={false}
        onExpand={handleClick}
        {...handlers}
      >
        <Liste.Header>
          {
            mode !== 'explorer'
              ? (
                <Header size="small">
                  {count}
                  {' '}
                  {(count > 1) ? 'projets' : 'projet' }
                </Header>
              )
              : (
                <Form>
                  <Form.Group>
                    <Form.Field
                      control={EtiquettesInput}
                      // @ts-expect-error allowAdditions
                      allowAdditions={false}
                      identifiantEntite={sessionStorage.getItem('identifiantEntite')}
                      onChange={handleEtiquetteChange}
                      selectedEtiquettes={selectedEtiquettes}
                      dropdownProps={{
                        className: 'etiquettes',
                      }}
                    />
                    <Form.Group className="filterCheckboxed">
                      <Form.Checkbox
                        label="Conception"
                        value={1}
                        checked={selectedStades.includes(1)}
                        onChange={handleStadeChange}
                      />
                      <Form.Checkbox
                        label="Planification"
                        value={2}
                        checked={selectedStades.includes(2)}
                        onChange={handleStadeChange}
                      />
                      <Form.Checkbox
                        label="Exécution"
                        value={3}
                        checked={selectedStades.includes(3)}
                        onChange={handleStadeChange}
                      />
                      <Form.Checkbox
                        label="Soldé"
                        value={4}
                        checked={selectedStades.includes(4)}
                        onChange={handleStadeChange}
                      />
                      <Form.Checkbox
                        label="Aucun"
                        value={0}
                        checked={selectedStades.includes(0)}
                        onChange={handleStadeChange}
                      />
                    </Form.Group>
                  </Form.Group>
                </Form>
              )
          }
        </Liste.Header>
        <Liste.Content>
          <ProjetListeTable
            items={projets}
            loading={loading}
            count={count}
            perPage={perPage}
            onLoad={onLoad}
            onSort={onSort}
            onSelect={onSelected}
            isExplorer={mode === 'explorer'}
          />
        </Liste.Content>

      </Liste>

      <Popup
        content="Nouvelle fiche"
        trigger={(
          <NavLink
            to="/projets/_"
            className="buttonAdd"
          >
            <Icon
              name="plus"
              circular
              inverted
              link
              size="large"
              color="blue"
            />
          </NavLink>
      )}
      />

    </>
  );
};

export default ProjetListe;
