import { combineServices } from '@jvs-group/jvs-mairistem-store';
import { createFicheService } from '@jvs-group/jvs-mairistem-fiche';
import { createListeService } from '@jvs-group/jvs-mairistem-liste';
import { isNil } from 'lodash';
import { Subvention } from '@/src/interfaces/subvention';

const getUrl = (data, endpoint: string, method: string) => {
  const { identifiant } = data;

  switch (method) {
    case 'GET':
      if (isNil(identifiant)) {
        return '/api/finances/subventionsPercues';
      }

      return `/api/finances/subventionsPercues/${identifiant}?modeEtendu=true`;
    case 'PUT': case 'DELETE':
      return `/api/finances/subventionsPercues/${identifiant}`;
    default:
      return '/api/finances/subventionsPercues/';
  }
};

const subventionService = createFicheService<Subvention>('subventionsPercues', getUrl);
const subventionListeService = createListeService<Subvention>(
  'subventionsPercues',
  getUrl,
  { meta: { endPoint: { search: '' }, perPage: 50 } },
);

export default combineServices<Subvention>(subventionListeService, subventionService);
