import React from 'react';
import { PanelClass } from '@jvs-group/jvs-mairistem-finances-utils';
import ImpactCollectiviteComponent from '@/src/components/Panel/ImpactCollectiviteComponent';
import { Projet } from '@/src/interfaces/projet';

class ImpactCollectivitePanel extends PanelClass {
  constructor(isMandatory: boolean) {
    super(isMandatory);
    this.code = 'IMG';
    this.icon = {
      name: 'like',
    };
  }

  getLibelle = () => 'Retombées pour la collectivité';

  isEmpty = (data: Projet) => !data?.impactCollectivite && !data?.impactCollectiviteTexte;

  getComponent = function getComponent(
    data: any,
    onChange: any,
    handlePin: any,
  ): React.ReactElement {
    return (
      <ImpactCollectiviteComponent
        data={data}
        handlePin={handlePin}
        onChange={onChange}
        panel={this}
      />
    );
  };
}

export default ImpactCollectivitePanel;
