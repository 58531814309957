import React from 'react';

import {
  Input,
  Currency,
  Grid,
  Confirm,
  Form,
} from '@jvs-group/jvs-mairistem-composants';

import { isNewIdentifiant } from '@jvs-group/jvs-mairistem-tools';
import { ErrorField }
  from '@jvs-group/jvs-mairistem-fiche/lib/ts/types';

import { Date } from '@jvs-group/jvs-mairistem-finances-utils';
import { handleMntAttribueChange } from '@/src/utils/conditionVersement';

import { isError } from '@/src/utils/tools';
import { ConditionVersement } from '@/src/interfaces/conditionVersement';

interface SubventionAttributionProps {
  identifiant: number,
  referenceDossier: string,
  dateDebut: string,
  mntAttribue: number,
  onChange: any,
  errors: Array<ErrorField>,
  conditionsVersements: Array<ConditionVersement>,
}

const SubventionAttribution = (
  {
    identifiant = null,
    referenceDossier = '',
    dateDebut = null,
    mntAttribue = 0,
    onChange = null,
    conditionsVersements = [],
    errors = [],
  } : SubventionAttributionProps,
) => {
  const [openPopupMontant, setOpenPopupMontant] = React.useState(false);
  const [previousMontantAttribue, setPreviousMontantAttribue] = React.useState(0);

  const handleChange = React.useCallback(({ target }) => {
    const {
      name,
      value,
    } = target;
    if (onChange) {
      onChange({
        identifiant,
        [name]: value,
      });
    }
  }, [identifiant, onChange]);

  const handleChangeMntAttribue = (e, data) => {
    if (!isNewIdentifiant(identifiant) && mntAttribue !== data && conditionsVersements?.length > 0) {
      setPreviousMontantAttribue(mntAttribue);
      setOpenPopupMontant(true);
    }

    onChange({
      identifiant,
      mntAttribue: data,
    });
  };

  const handleClosePopupMontant = () => {
    setOpenPopupMontant(false);
  };

  const handleRedispatcheMontant = () => {
    setOpenPopupMontant(false);

    const conditions = handleMntAttribueChange(
      conditionsVersements,
      mntAttribue,
      previousMontantAttribue,
    );

    if (conditions !== null) {
      onChange({
        identifiant,
        conditionsVersements: conditions,
      });
    }
  };

  const handleChangeDate = (value: string, name: string) => {
    onChange?.({
      identifiant,
      [name]: value,
    });
  };

  return (
    <Form>
      <Grid>
        <Grid.Row>
          <Grid.Column width={6}>
            <Currency
              key="mntAttribue"
              name="mntAttribue"
              id="subventionspercues-mntAttribue"
              label="Montant attribué"
              value={mntAttribue}
              placeholder="Montant attribué"
              onBlur={handleChangeMntAttribue}
              fluid
              error={isError(errors, 'subventionspercues-mntAttribue')}
              data-testid="mntAttribueSubvention"
            />
          </Grid.Column>
          <Grid.Column width={4}>
            <Form.Field
              control={Date}
              data-testid="dateDebutSubvention"
              // @ts-expect-error
              fluid
              key="dateDebut"
              label="Date attribution"
              name="dateDebut"
              onChange={handleChangeDate}
              value={dateDebut}
            />
          </Grid.Column>
          <Grid.Column width={6}>
            <Form.Field
              // @ts-expect-error
              control={Input}
              key="referenceDossier"
              name="referenceDossier"
              id="subventionspercues-referenceDossier"
              label="Référence du dossier"
              value={referenceDossier}
              placeholder="Référence du dossier"
              onChange={handleChange}
              fluid
              error={isError(errors, 'subventionspercues-referenceDossier')}
              data-testid="referenceDossierSubvention"
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Confirm
        open={openPopupMontant}
        onConfirm={handleRedispatcheMontant}
        onCancel={handleClosePopupMontant}
        cancelButton="non"
        confirmButton="oui"
        header="Vous avez modifié le montant de la subvention."
        content="Souhaitez-vous modifier le montant des conditions de versements proportionnellement ?"
      />
    </Form>
  );
};

export default SubventionAttribution;
