import React from 'react';

import {
  Grid, SectionItem, Container, Segment,
} from '@jvs-group/jvs-mairistem-composants';

import { formatMontant } from '@jvs-group/jvs-mairistem-finances-utils';
import { NavLink } from 'react-router-dom';
import { getLibelleEtatCondition } from '@/src/utils/conditionVersement';
import { toDateString } from '@/src/utils/tools';
import CircularProgressBar from '@/src/components/CircularProgressBar/CircularProgressBar';
import { ConditionVersement } from '@/src/interfaces/conditionVersement';
import renderEtatIcons from '@/src/utils/etatsIcons';

interface SubventionListeRicheItemProps {
  identifiant: number,
  libelle: string,
  referenceDossier: string,
  mntAttribue: number,
  nextCondition: ConditionVersement,
}

const SubventionListeRicheItem = ({
  identifiant,
  libelle,
  referenceDossier,
  mntAttribue,
  nextCondition,
}: SubventionListeRicheItemProps) => {
  const condition = nextCondition?.[0] || {};

  return (
    <NavLink to={`/subventionsPercues/${identifiant}`}>
      <Grid padded>
        <Grid.Row>
          <Grid.Column computer={6} tablet={16}>
            <Grid columns={2}>
              <Grid.Row>
                <Grid.Column>Subvention:</Grid.Column>
                <Grid.Column className="value">{libelle}</Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>N&deg; de dossier:</Grid.Column>
                <Grid.Column className="value">{referenceDossier}</Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>Montant global attribué:</Grid.Column>
                <Grid.Column className="value">{formatMontant(mntAttribue)}</Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>

          <Grid.Column computer={4} tablet={16}>
            <SectionItem
              title={condition?.libelle}
              single
              compact
            >
              <Container as={Segment}>
                <Grid columns={2}>
                  <Grid.Row>
                    <Grid.Column>Montant:</Grid.Column>
                    <Grid.Column className="value">
                      {formatMontant(condition?.montant)}
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>Condition:</Grid.Column>
                    <Grid.Column className="value">
                      {condition?.pourcentageExecution}
                      {' '}
                      % d&apos;exécution
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>Date limite:</Grid.Column>
                    <Grid.Column className="value">
                      {condition?.dateButoire ? toDateString(condition?.dateButoire) : '-'}
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Container>

            </SectionItem>
          </Grid.Column>

          <Grid.Column computer={3} tablet={16}>
            <Grid>
              <Grid.Row className="etatRow">
                Etat:
                <span>
                  {renderEtatIcons(condition)}
                </span>
                <div className="value">
                  {getLibelleEtatCondition(condition)}
                </div>
              </Grid.Row>
            </Grid>
          </Grid.Column>

          <Grid.Column textAlign="center" computer={3} tablet={16}>
            <CircularProgressBar
              width={200}
              value={condition?.pourcentageRealise}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </NavLink>
  );
};

export default SubventionListeRicheItem;
