// @ts-nocheck

import React from 'react';
import { ListeTable } from '@jvs-group/jvs-mairistem-liste';
import { Projet } from '@/src/interfaces/projet';

interface ProjetListeTableProps {
  items: Array<Projet>,
  count: number,
  perPage: number,
  loading: boolean,
  onLoad: any,
  onSelect: any,
  onSort: any,
  isExplorer: boolean,
}

const projetsStades = ['Aucun', 'Conception', 'Planification', 'Exécution', 'Soldé'];

const ProjetListeTable = ({
  items,
  count,
  perPage,
  loading,
  onLoad,
  onSort,
  onSelect,
  isExplorer,
} : ProjetListeTableProps) => (
  <ListeTable
    items={items}
    loading={loading}
    count={count}
    perPage={perPage}
    onLoad={onLoad}
    onSort={onSort}
    onSelect={onSelect}
  >
    <ListeTable.Header>
      <ListeTable.Column
        name="libelle"
        width={8}
      >
        Intitulé
      </ListeTable.Column>
    </ListeTable.Header>

    {
    isExplorer
      && (
        <ListeTable.Header>
          <ListeTable.Column
            sortable
            name="referenceInterne"
            width={4}
          >
            Références interne
          </ListeTable.Column>

          <ListeTable.Column
            sortable
            name="status"
            width={4}
          >
            Stade
          </ListeTable.Column>
        </ListeTable.Header>
      )
}
    {(projet) => (
      <ListeTable.Row>
        <ListeTable.Cell name="libelle">{projet?.libelle}</ListeTable.Cell>
        {
       isExplorer
       && (
         <>
           <ListeTable.Cell name="referenceInterne">
             {projet?.referenceInterne}
           </ListeTable.Cell>
           <ListeTable.Cell name="status">{projetsStades[projet.status]}</ListeTable.Cell>
         </>
       )
   }
      </ListeTable.Row>
    )}
  </ListeTable>
);

export default ProjetListeTable;
