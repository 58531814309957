import React from 'react';
import { PanelClass } from '@jvs-group/jvs-mairistem-finances-utils';
import ImpactEnvironnementComponent from '@/src/components/Panel/ImpactEnvironnementComponent';
import { Projet } from '@/src/interfaces/projet';

class ImpactEnvironnementPanel extends PanelClass {
  constructor(isMandatory: boolean) {
    super(isMandatory);
    this.code = 'ENV';
    this.icon = {
      name: 'tree',
    };
  }

  getLibelle = () => "Impact sur l'environnement";

  isEmpty = (data: Projet) => !data?.impactEnvironnement && !data?.impactEnvironnementTexte;

  getComponent = function getComponent(
    data: any,
    onChange: any,
    handlePin: any,
  ): React.ReactElement {
    return (
      <ImpactEnvironnementComponent
        data={data}
        handlePin={handlePin}
        onChange={onChange}
        panel={this}
      />
    );
  };
}

export default ImpactEnvironnementPanel;
