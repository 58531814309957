import React from 'react';

import { NATURES_DOCUMENTS, formatMontant, getErrorMessage } from '@jvs-group/jvs-mairistem-finances-utils';
import {
  Button, Modal, SemanticCOLORS, Segment, Placeholder,
} from '@jvs-group/jvs-mairistem-composants';
import { isNil } from 'lodash';
import { request } from '@jvs-group/jvs-mairistem-store';
import { toast } from 'react-toastify';
import { ConditionVersement } from '@/src/interfaces/conditionVersement';
import '@/src/components/SubventionFichePanel/ConditionsVersements/conditionVersement.css';
import { Subvention } from '@/src/interfaces/subvention';
import { fetchConditionGabarits } from '@/src/utils/piecesJointes';
import NatureCondition from '@/src/interfaces/natureCondition';

interface PreparationModalProps {
  onClose: any,
  color: SemanticCOLORS,
  conditionVersement: ConditionVersement,
  subvention: Subvention,
  onDownloadSuccess: (conditionVersement: ConditionVersement) => void,
  mountNode: ChildNode,
}

const PreparationModal = (
  {
    conditionVersement = null,
    subvention = null,
    onClose = null,
    color = null,
    onDownloadSuccess = null,
    mountNode = null,
  } : PreparationModalProps,
) => {
  const [isDownloading, setIsDownloading] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [missingPjs, setMissingPjs] = React.useState<NatureCondition[]>();

  const copyTextDemande = () => {
    const demandeText = document.getElementById('demandeText');
    navigator.clipboard.writeText(demandeText?.textContent);
  };

  const downloadDocuments = async () => {
    setIsDownloading(true);

    try {
      request.download(
        `/api/finances/subventionsPercues/conditionVersement/${conditionVersement.identifiant}/downloadZip`,
      );
      onDownloadSuccess(conditionVersement);
    } catch (e) {
      toast.error(getErrorMessage(e, 'Une erreur est survenue pendant le téléchargement'));
    } finally {
      setIsDownloading(false);
    }
  };

  const formatDate = (dateString : string) : string => {
    if (isNil(dateString)) {
      return '';
    }

    const d = new Date(dateString);
    return d.toLocaleDateString(
      'fr-FR',
      {
        year: 'numeric', month: 'long', day: 'numeric',
      },
    );
  };

  /* Recupere la liste des pjs manquantes parametre sur la condition */
  const getMissingPjFromNature = React.useCallback(async () => {
    const [{ data: pjsSubvention }, { data: pjsProjet }] = await fetchConditionGabarits(
      subvention?.identifiant,
      subvention?.identifiantProjet,
    );

    const missingPjs = conditionVersement?.naturesConditions?.filter(
      (n) => !pjsSubvention?.some((pj) => pj.codeNature === n.codeNature)
              && !pjsProjet?.some((pj) => pj.codeNature === n.codeNature),
    );

    setIsLoading(false);
    setMissingPjs(missingPjs);
  }, [conditionVersement?.naturesConditions, subvention?.identifiant, subvention?.identifiantProjet]);

  React.useEffect(() => {
    getMissingPjFromNature();
  }, [getMissingPjFromNature]);

  const getLibelleNature = (codeNature: string) => NATURES_DOCUMENTS?.find(
    (n) => n.identifiant === codeNature,
  )?.libelle ?? '';

  const renderContent = () => {
    if (isLoading) {
      return (
        <Modal.Content key="content" as={Segment} id="demandeText">
          <Placeholder>
            <Placeholder.Header image>
              <Placeholder.Line />
              <Placeholder.Line />
            </Placeholder.Header>
            <Placeholder.Paragraph>
              <Placeholder.Line />
              <Placeholder.Line />
              <Placeholder.Line />
              <Placeholder.Line />
            </Placeholder.Paragraph>
          </Placeholder>
        </Modal.Content>
      );
    }
    return (
      <Modal.Content key="content" as={Segment} id="demandeText">
        Par Arrêté n°
        {' '}
        <span className="bold">{subvention?.referenceDossier}</span>
        , une subvention de
        {' '}
        <span className="bold">
          {subvention?.mntAttribue}
          &euro;
        </span>
        {' '}
        portant au taux de
        {' '}
        <span className="bold">
          {subvention?.tauxAide}
          %
        </span>
        {' '}
        {subvention?.plafondEligible > 0 && (
        <>
          sur une dépense subventionnable plafonnée à
          {' '}
          <span className="bold">
            {subvention?.plafondEligible}
            &euro;
            {' '}
            {subvention?.realisationHT ? 'HT' : 'TTC'}
            {' '}
          </span>
        </>
        )}
        a été accordée à la collectivité pour assurer le financement du projet
        {' '}
        <span className="bold">
          &quot;
          {subvention?.projet?.libelle}
          &quot;.
        </span>
        <br />
        <br />
        Ce projet ayant un commencement d&apos;exécution le
        {' '}
        <span className="bold">{formatDate(subvention?.projet?.dateExecution)}</span>
        {' '}
        , et à l&apos;appui des factures acquittées, je sollicite le versement
        {' '}
        <span className="bold">{conditionVersement?.libelle}</span>
        {' '}
        de
        {' '}
        <span className="bold">
          {formatMontant((conditionVersement.montant * 100) / subvention.mntAttribue)}
          {' '}
          %
        </span>
        {' '}
        de ladite subvention, soit la somme de
        {' '}
        <span className="bold">
          {conditionVersement?.montant}
          &euro;.
        </span>
        <br />

        {missingPjs?.length > 0 && (
        <>
          <br />
          Attention ces justificatifs à fournir n&apos;ont pas été retrouvés:
          {missingPjs?.map((missingPJ) => (
            <>
              <br />
              -
              {' '}
              {getLibelleNature(missingPJ?.codeNature)}
            </>
          ))}
        </>
        )}
      </Modal.Content>
    );
  };

  return (
    <Modal
      closeIcon
      open
      colorHeader={color}
      onClose={() => onClose()}
      mountNode={mountNode}
    >
      <Modal.Header key="header">Préparation de la demande de versement</Modal.Header>

      {renderContent()}

      <Modal.Actions>
        <Button onClick={downloadDocuments} loading={isDownloading}>
          Récupérer les documents
        </Button>
        <Button onClick={copyTextDemande}>
          Copier le texte
        </Button>
        <Button onClick={() => onClose()}>
          Quitter
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default PreparationModal;
